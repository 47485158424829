/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { getImageSrc } from "../../../utils/common";
import chatIcon from '../../../assets/images/forum.png'

export default function MessageContent({
    message,
    user,
}) {
    const { content, meta, timestamp } = message;
    return (
        <div key={Date.now() + Math.random()}>
            <div className="py-2 d-flex">
                <img className='pic-box' src={getImageSrc(meta?.user?.image)} alt="" />
                <div className="ps-3 w-100">
                    <span >{meta?.user?.username}</span>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <p className="text-break text-gray mt-1">{content.text}</p>
                        </div>
                        <div>
                            <small className="text-gray">{timestamp}</small> {/* Display timestamp */}
                        </div>
                    </div>
                    {/* <button
                            className="btn btn-link reply-button"
                            // onClick={() => onReply(message)}
                        >
                            Reply
                        </button> */}
                </div>
            </div>
        </div>
    );
}