// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap {
  background-color: #070123;
  width: 100%;
  padding-top: 10rem;
}

.drop-btn {
  background-color: transparent;
  font-size: 12px;
  padding: 15px 10px;
  border: 1px solid white;
  border-radius: 22px;
}

.winner {
  font-size: 12px;
}

.btn-load {
  padding: 2px;
  max-width: 300px;
}
.btn-load-inner {
  border-radius: 15px;
}

.winner-sec p {
  padding-left: 18px;
}

.text-elips {
  max-width: 250px;
}

@media (max-width: 1100px) {
  .text-elips {
    max-width: 100px;
  }
}
@media (max-width: 907px) {
  .text-elips {
    max-width: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/winner/winner.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,WAAA;EACF,kBAAA;AACF;;AAGA;EACI,6BAAA;EACA,eAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;;AAIA;EACI,eAAA;AADJ;;AAKA;EACC,YAAA;EACA,gBAAA;AAFD;AAGC;EACG,mBAAA;AADJ;;AAOA;EACI,kBAAA;AAJJ;;AAQA;EACI,gBAAA;AALJ;;AAOA;EACI;IACI,gBAAA;EAJN;AACF;AAOC;EACD;IACI,gBAAA;EALF;AACF","sourcesContent":[".wrap{\n    background-color: #070123;\n    width: 100%;\n  padding-top: 10rem;\n  \n}\n\n.drop-btn{\n    background-color: transparent;\n    font-size: 12px;\n    padding: 15px 10px;\n    border:1px solid white;\n    border-radius: 22px;\n}\n\n\n.winner{\n    font-size: 12px;\n}\n\n\n.btn-load{\n padding: 2px;   \n max-width: 300px;\n &-inner{\n    border-radius: 15px;\n }\n}\n\n\n\n.winner-sec p{\n    padding-left: 18px;\n}\n\n\n.text-elips {\n    max-width: 250px;\n}\n@media (max-width: 1100px){\n    .text-elips {\n        max-width: 100px;\n    }\n    }\n\n @media (max-width: 907px){\n.text-elips {\n    max-width: 100px;\n}\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
