import { Fragment, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { scrollIntoView } from "../utils/common";
import Header from "./header/header.components";
import Footer from "./footer/footer.component";
import { useConnection } from '../utils/thirdweb'
import { useInterceptors } from "../utils/api";
import { useActiveAccount, useAutoConnect } from "thirdweb/react";
import { toast } from "react-toastify";
import { post } from "../utils/api/apiHelper";
import { STORAGE_KEYS } from "../config";

export default function AuthLayout() {
    const activeAccount = useActiveAccount();
    const redirect = useNavigate();
    const { checkConnection } = useConnection();
    const { pathname, hash } = useLocation();
    const setInterceptors = useInterceptors();
    const authenticateDiscord = async () => {
        let loadingToast;
        try {
            const query = new URLSearchParams(window.location.search);
            const code = query.get('code');
            console.log("code", code);
            if (code) {
                loadingToast = toast.loading("Connecting Discord ...");
                let response = await post('/client/authenticate_discord', {
                    redirect_uri: `${window.location.origin}/allowlist`,
                    code
                });
                // redirect('/allowlist', {
                //     replace: true
                // });
                // window.open('https://discord.gg/3syprfcb8r')
                localStorage.setItem(STORAGE_KEYS.discord, JSON.stringify(response.data.creds));
            }
        } catch (error) {
            console.log(error);
            toast.error("Failed To Connect !", {
                toastId: "error"
            });
        }
        finally {
            toast.done(loadingToast);
        }
    }
    useEffect(() => {
        setInterceptors();
        checkConnection();
        scrollIntoView(hash);
        if (activeAccount?.address) {
            authenticateDiscord();
        }
    }, [pathname, hash, activeAccount?.address, setInterceptors, checkConnection]);
    if (activeAccount?.address) {
        return (
            <Fragment>
                <Header />
                <Outlet />
                <Footer />
            </Fragment>
        );
    }
}