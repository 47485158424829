// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-btn {
  text-align: center;
}
.social-btn button {
  background-color: white;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 12px;
  border-radius: 10px;
  margin: 0px 6px;
}
@media (max-width: 767px) {
  .social-btn button {
    padding: 10px;
  }
}

.social-buttons {
  padding: 40px 0px 40px;
}

.statistics.winner-box {
  padding: 20px 10px;
}

.p-text {
  font-size: 12px;
  color: #d6d6d6;
}

.prizepooldiv {
  border-radius: 16px;
  background-color: rgba(59, 87, 238, 0.2);
}

.prize-text {
  color: #35D2E2;
}

.t-prize {
  font-size: 12px;
  color: #fff;
}

.t-640 {
  color: #A4A9C7;
  font-size: 40px;
}

.upcoming {
  padding: 10px;
}

.border-battle {
  border-bottom: 2px solid #1A0E56;
}

.bordertop {
  border-bottom: 4px solid #3B57EE;
}

.pic-boxx {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/battleended/battleended.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ;AAGI;EACI,uBAAA;EACA,0CAAA;EACA,aAAA;EACA,mBAAA;EAEA,eAAA;AAFR;AAIQ;EARJ;IASQ,aAAA;EADV;AACF;;AAKA;EACI,sBAAA;AAFJ;;AAOA;EACI,kBAAA;AAJJ;;AASA;EACI,eAAA;EACA,cAAA;AANJ;;AASA;EACI,mBAAA;EACA,wCAAA;AANJ;;AASA;EACI,cAAA;AANJ;;AAUA;EACI,eAAA;EACA,WAAA;AAPJ;;AAWA;EACI,cAAA;EACA,eAAA;AARJ;;AAWA;EACI,aAAA;AARJ;;AAWA;EACI,gCAAA;AARJ;;AAWA;EACI,gCAAA;AARJ;;AAUA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AAPJ","sourcesContent":["@import '../../assets/scss/responsive';\n\n.social-btn {\n    text-align: center;\n\n    button {\n        background-color: white;\n        border: 1px solid rgba(255, 255, 255, 0.4);\n        padding: 12px;\n        border-radius: 10px;\n\n        margin: 0px 6px;\n\n        @media(max-width:$breakpoint-mobile) {\n            padding: 10px;\n        }\n    }\n}\n\n.social-buttons {\n    padding: 40px 0px 40px;\n\n}\n\n\n.statistics.winner-box {\n    padding: 20px 10px;\n}\n\n\n\n.p-text {\n    font-size: 12px;\n    color: #d6d6d6;\n}\n\n.prizepooldiv {\n    border-radius: 16px;\n    background-color: rgba(59, 87, 238, 0.20);\n}\n\n.prize-text {\n    color: #35D2E2;\n\n}\n\n.t-prize {\n    font-size: 12px;\n    color: #fff;\n\n}\n\n.t-640 {\n    color: #A4A9C7;\n    font-size: 40px;\n}\n\n.upcoming {\n    padding: 10px;\n}\n\n.border-battle {\n    border-bottom: 2px solid #1A0E56;\n}\n\n.bordertop {\n    border-bottom: 4px solid #3B57EE;\n}\n.pic-boxx{\n    width: 55px;\n    height: 55px;\n    border-radius: 50%;\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
