// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-popup .modal-content {
  background: #1A0D58;
  color: #fff;
  text-align: center;
  font-family: inherit;
  box-shadow: 0px 0px 64px rgba(95, 178, 255, 0.35);
}

.confirm-popup .buttons {
  color: #fff;
  border-radius: 10px;
  justify-content: center;
  background: linear-gradient(29deg, rgb(255, 129, 125) 0%, rgb(130, 51, 255) 37%, rgb(56, 87, 253) 73%, rgb(34, 222, 241) 100%);
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  margin-inline-end: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ConfirmPopUp/confirmpopup.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,oBAAA;EACA,iDAAA;AACJ;;AACA;EACI,WAAA;EACA,mBAAA;EACA,uBAAA;EACA,8HAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,uBAAA;AAEJ","sourcesContent":[".confirm-popup .modal-content {\n    background: #1A0D58;\n    color: #fff;\n    text-align: center;\n    font-family: inherit;\n    box-shadow: 0px 0px 64px rgba(95, 178, 255, 0.35);\n}\n.confirm-popup .buttons{\n    color: #fff;\n    border-radius: 10px;\n    justify-content: center;\n    background: linear-gradient(29deg, rgb(255, 129, 125) 0%, rgb(130, 51, 255) 37%, rgb(56, 87, 253) 73%, rgb(34, 222, 241) 100%);\n    border: none;\n    text-transform: uppercase;\n    padding: 10px 20px;\n    margin-inline-end: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
