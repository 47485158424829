import React, { useCallback, useEffect } from 'react';
import './header.scss';
import logo from '../../assets/images/logo.svg';
import logo2 from '../../assets/images/Group13.png';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Container } from 'react-bootstrap';
import user from '../../assets/images/user.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, showConnectModal, showRefferModal } from '../../redux/slices/commonSlice';
import { Link, useNavigate } from 'react-router-dom';
import { logOut, login } from '../../redux/slices/userSlice';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { get, post } from '../../utils/api/apiHelper';
import { useInterceptors } from '../../utils/api';

import { useActiveAccount, useDisconnect, useActiveWallet } from "thirdweb/react";
const Header = () => {
  const activeAccount = useActiveAccount();
  const { disconnect } = useDisconnect();
  const wallet = useActiveWallet();

  const { isLogin } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const redirect = useNavigate();

  const logOutHandler = useCallback(() => {
    const options = {
      title: 'Are you sure you want to logout?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            disconnect(wallet);
            dispatch(logOut());
            localStorage.setItem('accessToken', "");
            localStorage.setItem('walletAddress', "");
            redirect('/battles');
            toast.success("Logout successfully!");
          }
        },
        {
          label: 'No'
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "custom-alert-overlay"
    };
    confirmAlert(options);
  }, [disconnect, dispatch, redirect, wallet]);

  const setShow = (v) => {
    dispatch(showConnectModal(v));
  }
  const navigateORConnect = async () => {
    if (!activeAccount?.address) {
      setShow(true); // Set show to true only when not connected
      return;
    }
    try {
      const response = await get('/client/profile');
      if (response?.status === 200) {
        const { checkAllowlist } = response.data;
        if (checkAllowlist) {
          redirect('/thankyou');
        } else {
          redirect('/allowlist');
        }
      }
    } catch (error) {
      console.error("Error fetching profile data", error);
    }
  }
  const setInterceptors = useInterceptors();

  const handleLogin = async () => {
    try {
      let response = await post('/client/login', { walletName: "SMARTWALLET", walletAddress: activeAccount?.address });
      console.log('444', response?.data);
      if (response?.status === 200) {
        dispatch(login({ ...response.data }));
        localStorage.setItem('user-info', JSON.stringify(response.data));
        localStorage.setItem('accessToken', response.data.token);
        localStorage.setItem('walletAddress', response.data.walletAddress);
        setInterceptors();
        if (response?.data?.isNew) {
          dispatch(showRefferModal(true))
        }
        // toast.success('Connected Successfully !', {
        //   autoClose: 2000,
        //   toastId: 'connect'
        // });
      } else {
        toast.error(response?.error);
      }
      dispatch(setLoading(false));
    } catch (error) {
      console.error("Login failed:", error);
      toast.error(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (activeAccount?.address) {
      handleLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount, isLogin]);

  return (
    <Container>
      <header className='header-element  bg-white'>
        <Navbar expand="lg" className="py-0">
          <Navbar.Brand href="" className='cursor-pointer' onClick={() => redirect('/')}><img src={logo} alt="" /></Navbar.Brand>
          <img src={logo2} alt="" className='d-none' />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className=" mx-auto ">
              <span className='nav-link cursor-pointer' onClick={navigateORConnect}>Allowlist</span>
              <Link className='nav-link' to="/winners">Winners</Link>
              <Link className='nav-link' to="/leaderboard">Leaderboard</Link>
              <Link className='nav-link' to='/battles'>Battle Now</Link>
            </Nav>
            <div className='d-flex '>
              {activeAccount?.address ? (
                <div className='d-flex gap-2'>
                  <Dropdown>
                    <Dropdown.Toggle className="rounded p-3 bg-gadient rounded-4 ms-2" id="dropdown-basic">
                      <img src={user} alt='' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='rounded-3'>
                      <Dropdown.Item href="" onClick={() => redirect('/profile')}><i className="fa fa-user " aria-hidden="true"></i> My profile</Dropdown.Item>
                      <Dropdown.Item href="" onClick={() => redirect('/notifications')}><i className="fa fa-cog" aria-hidden="true"></i> Settings</Dropdown.Item>
                      <Dropdown.Item href="" onClick={logOutHandler}><i className="fa fa-sign-in" aria-hidden="true"></i> logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <Button
                  variant="primary btn-header btn-gadient"
                  onClick={() => setShow(true)}
                >
                  Connect
                </Button>
              )}
            </div>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </Container>
  );
}

export default Header;
