// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-terms {
  padding-right: 20px;
}

.pt-banner {
  padding-top: 130px;
}
@media (max-width: 767px) {
  .pt-banner {
    padding-top: 18rem;
  }
}

html {
  scroll-padding-top: 110px;
}
@media (max-width: 767px) {
  html {
    scroll-padding-top: 18rem;
  }
}

.terms {
  font-size: 60px;
  font-style: normal;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .terms {
    font-size: 30px;
  }
}

.policy-sidebar {
  position: sticky;
  top: 110px;
}
@media (max-width: 767px) {
  .policy-sidebar {
    position: fixed;
    top: -9px;
    width: 100%;
    background: #070123;
    padding-top: 10rem;
  }
}

.inner-term {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: var(--gray, #A4A9C7);
}
@media (max-width: 767px) {
  .inner-term {
    font-size: 16px;
  }
}

.navi {
  color: #A4A9C7;
  font-size: 16px;
  font-weight: 400px;
}

.privacy {
  font-size: 60px;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/policy/policy.scss"],"names":[],"mappings":"AACA;EACI,mBAAA;AAAJ;;AAEA;EACI,kBAAA;AACJ;AAAI;EAFJ;IAGQ,kBAAA;EAGN;AACF;;AADA;EACI,yBAAA;AAIJ;AAHI;EAFJ;IAGQ,yBAAA;EAMN;AACF;;AAJA;EAEI,eAAA;EACA,kBAAA;EACA,yBAAA;AAMJ;AALI;EALJ;IAMQ,eAAA;EAQN;AACF;;AAJA;EACI,gBAAA;EACA,UAAA;AAOJ;AANI;EAHJ;IAIQ,eAAA;IACA,SAAA;IACA,WAAA;IACA,mBAAA;IACA,kBAAA;EASN;AACF;;AANA;EACI,eAAA;EACJ,kBAAA;EACA,gBAAA;EAIA,2BAAA;AAMA;AATA;EAJA;IAKI,eAAA;EAYF;AACF;;AALA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;AAQJ;;AALA;EACA,eAAA;EACA,yBAAA;AAQA","sourcesContent":["@import '../../assets/scss/responsive';\n.main-terms{\n    padding-right: 20px;\n}\n.pt-banner{\n    padding-top: 130px;\n    @media(max-width:$breakpoint-mobile){\n        padding-top: 18rem;\n    }\n}\nhtml{\n    scroll-padding-top: 110px;\n    @media(max-width:$breakpoint-mobile){\n        scroll-padding-top: 18rem;\n    }\n}\n.terms{\n    \n    font-size: 60px;\n    font-style: normal;  \n    text-transform: uppercase;\n    @media(max-width:$breakpoint-mobile){\n        font-size: 30px;\n    }\n\n}\n\n.policy-sidebar{\n    position: sticky;\n    top: 110px;\n    @media(max-width:$breakpoint-mobile){\n        position: fixed;\n        top: -9px;\n        width: 100%;\n        background: #070123;\n        padding-top: 10rem;\n    }\n}\n\n.inner-term{\n    font-size: 18px;\nfont-style: normal;\nfont-weight: 400;\n@media(max-width:$breakpoint-mobile){\n    font-size: 16px;\n}\ncolor: var(--gray, #A4A9C7);\n}\n\n\n\n\n.navi{\n    color:#A4A9C7;\n    font-size:16px;\n    font-weight: 400px;\n}\n\n.privacy{\nfont-size: 60px;\ntext-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
