/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import { Nav } from "react-bootstrap";
import { toast } from 'react-toastify';
import { get } from "../../utils/api/apiHelper";
import BattleEntered from './battle-enter/battleEnter.component'
import WalletHistory from './wallethistory/wallethistory.component'
import ProfileHeader from "./profile-header/profileheader.component";
// import ethereum from '../../../assets/images/ethereum-eth-logo.svg';
// import Dropdown from 'react-bootstrap/Dropdown';
// import { BsPlus } from 'react-icons/bs';
// import sort from "../../../assets/images/sortbtn.svg"
import AddBalance from '../modal/modal.component'
import './profile.scss';
import { useActiveAccount } from "thirdweb/react";

const MyProfile = () => {
  const activeAccount = useActiveAccount();
  const [balance, setBalance] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const getWallet_History = useCallback(async () => {
    try {
      if (activeAccount?.address) {
        let response = await fetch(`https://api-sepolia.etherscan.io/api?module=account&action=txlist&address=${activeAccount?.address}&page=${currentPage}&offset=${perPage}&sort=desc`);
        if (response.ok) {
          let json = await response.json();
          console.log(json, 'response.data.items');
          setItems(json?.result ?? []);
          setTotal(json?.result?.length ?? 0);
        }
      }
    } catch (error) {
      toast.error('Something went wrong!', {
        toastId: "error"
      });
    }
  }, [currentPage, perPage, activeAccount?.address]);

  useEffect(() => {
    getWallet_History();
  }, [getWallet_History]);

  return (
    <div className="bg-dark-1 pt-head">
      <div className="container  ">
        <ProfileHeader setModalShow={setModalShow} />
        <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="">
          <div className="flex-row d-flex mt-5">
            <Nav variant="pills" className="tab_section ">
              <Nav.Item>
                <Nav.Link eventKey="first" className="upcome h2 ">Battle Entered</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second" className="h2">Wallet History</Nav.Link>
              </Nav.Item>
            </Nav>
            {/* <Dropdown className="ms-auto">
              <Dropdown.Toggle id="dropdown-basic" className='text-light dropbtn drop-btn'>
                <span><img src={sort} width={20} alt="" /></span>
                All
              </Dropdown.Toggle>
              <Dropdown.Menu >
                <Dropdown.Item href="#">A-Z</Dropdown.Item>
                <Dropdown.Item href="#">Date</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
          <Tab.Content className="mt-4">
            <hr className="rounded " />
            <Tab.Pane eventKey="first"> <BattleEntered />  </Tab.Pane>
            <Tab.Pane eventKey="second"><WalletHistory setCurrentPage={setCurrentPage} perPage={perPage} currentPage={currentPage} total={total} items={items} /></Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      {/* <AddBalance show={modalShow} onHide={() => setModalShow(false)} balance={balance} updateData={() => {
        getBalance();
        getWallet_History();
      }} /> */}
    </div>
  );
};

export default MyProfile;
