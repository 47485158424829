import React from 'react';
import './header.scss';
import logo from '../../assets/images/logo.svg';
import logo2 from '../../assets/images/Group13.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';


const HomeHeader = () => {
  const redirect = useNavigate();

  return (
    <Container>
      <header className='header-element  bg-white'>
        <Navbar expand="lg" className="py-0">
          <Navbar.Brand href="" onClick={() => redirect('/')}><img src={logo} alt="" /></Navbar.Brand>
          <img src={logo2} alt="" className='d-none' />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className=" mx-auto ">
              <Link className='nav-link' to='/#howitworks'>Process</Link>
              <Link className='nav-link' to='/#aboutus'>About</Link>
              <Link className='nav-link' to='/#faq'>FAQ</Link>
              <Link className='nav-link' to='/#our_journey'>Journey</Link>
            </Nav>
            <div className='d-flex '>
              {/* <Button variant="primary btn-header btn-gadient" onClick={() => redirect('/battles')}>Battle Now</Button> */}
              <Button variant="primary btn-header btn-gadient" onClick={() => window.open('/battles', "_blank")}>Battle Now</Button>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </Container>
  )
}

export default HomeHeader;