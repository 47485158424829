import config from "../config.json";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setLoading, showConnectModal } from "../redux/slices/commonSlice";
import { useNavigate } from "react-router-dom";
import { get } from "./api/apiHelper";
import { login } from "../redux/slices/userSlice";

// --------- v5 ------- //
import { createThirdwebClient } from "thirdweb";
import { useActiveAccount } from "thirdweb/react";


const privateKeyLink = () => {
    return <a target="_blank" rel="noreferrer" style={{ fontSize: "smaller" }} href={`https://embedded-wallet.thirdweb.com/sdk/2022-08-12/embedded-wallet/export?clientId=${config.REACT_PUBLIC_TEMPLATE_CLIENT_ID}`}>Export your wallet</a>
}

const useConnection = () => {
    const activeAccount = useActiveAccount();
    const redirect = useNavigate();
    const dispatch = useDispatch();
    const checkConnection = useCallback((force = true) => {
        dispatch(setLoading(true));
        if (activeAccount?.address) {
            get('/client/profile').then(response => {
                if (response?.status === 200) {
                    let token = localStorage.getItem('accessToken');
                    dispatch(login({ ...response.data, token }));
                } else {
                    redirect('/battles')
                }
            }).finally(() => {
                dispatch(setLoading(false));
            });
        } else {
            dispatch(setLoading(false));
            if (force) {
                dispatch(showConnectModal(true))
                redirect('/battles')
            }
        }
    }, [dispatch, activeAccount?.address, redirect])

    return { checkConnection }
}
export { privateKeyLink, useConnection }



// --------- v5 ------- //

export const client = createThirdwebClient({
    clientId: config.REACT_PUBLIC_TEMPLATE_CLIENT_ID,
});