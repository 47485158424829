// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-element {
  border-radius: 20px;
  padding: 10px 20px;
  position: fixed;
  width: 80%;
  margin: 0px auto;
  left: 0;
  right: 0;
  top: 10px;
  z-index: 999;
  max-width: 1300px;
}
@media (max-width: 767px) {
  .header-element .navbar-brand {
    width: 100px;
  }
}

.btn-gadient {
  background: linear-gradient(29deg, rgb(255, 129, 125) 0%, rgb(130, 51, 255) 37%, rgb(56, 87, 253) 73%, rgb(34, 222, 241) 100%);
  border: none;
  text-transform: uppercase;
}

.btn-header {
  padding: 10px 20px;
  border-radius: 10px;
}

.custom-alert-overlay .react-confirm-alert-body {
  background: #1A0D58;
  color: #fff;
  text-align: center;
  font-family: inherit;
  box-shadow: 0px 0px 64px rgba(95, 178, 255, 0.35);
}

.custom-alert-overlay.react-confirm-alert-overlay {
  background: rgba(19, 16, 40, 0.8);
  z-index: 999;
}

.custom-alert-overlay .react-confirm-alert-body h1 {
  font-size: 24px;
}

.custom-alert-overlay .react-confirm-alert-button-group {
  justify-content: center;
}

.custom-alert-overlay .react-confirm-alert-button-group button {
  justify-content: center;
  background: linear-gradient(29deg, rgb(255, 129, 125) 0%, rgb(130, 51, 255) 37%, rgb(56, 87, 253) 73%, rgb(34, 222, 241) 100%);
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
}`, "",{"version":3,"sources":["webpack://./src/layout/header/header.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,UAAA;EACA,gBAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,iBAAA;AACJ;AAAA;EACI;IACI,YAAA;EAEN;AACF;;AACA;EACI,8HAAA;EACA,YAAA;EACA,yBAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,mBAAA;AAGJ;;AAAA;EACI,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,oBAAA;EACA,iDAAA;AAGJ;;AAAA;EACG,iCAAA;EACA,YAAA;AAGH;;AACA;EACI,eAAA;AAEJ;;AAAA;EACI,uBAAA;AAGJ;;AADA;EACI,uBAAA;EACA,8HAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AAIJ","sourcesContent":[".header-element{\n    border-radius: 20px;\n    padding: 10px 20px;\n    position: fixed;\n    width: 80%;\n    margin: 0px auto;\n    left: 0;\n    right: 0;\n    top: 10px;\n    z-index: 999;\n    max-width: 1300px;\n@media(max-width:767px){\n    .navbar-brand{\n        width:100px;\n    }\n}\n}\n.btn-gadient{\n    background: linear-gradient(29deg, rgba(255,129,125,1) 0%, rgba(130,51,255,1) 37%, rgba(56,87,253,1) 73%, rgba(34,222,241,1) 100%);\n    border: none;\n    text-transform: uppercase;\n}\n.btn-header{\n    padding: 10px 20px;\n    border-radius: 10px;\n}\n\n.custom-alert-overlay .react-confirm-alert-body{\n    background: #1A0D58;\n    color: #fff;\n    text-align: center;\n    font-family: inherit;\n    box-shadow:  0px 0px 64px rgba(95, 178, 255, 0.35);\n   \n}\n.custom-alert-overlay.react-confirm-alert-overlay{\n   background: rgba(19, 16, 40, 0.8);\n   z-index: 999;\n   \n}\n\n.custom-alert-overlay .react-confirm-alert-body h1{\n    font-size: 24px;\n}\n.custom-alert-overlay .react-confirm-alert-button-group{\n    justify-content:center;\n}\n.custom-alert-overlay .react-confirm-alert-button-group button{\n    justify-content:center;\n    background: linear-gradient(29deg, rgba(255,129,125,1) 0%, rgba(130,51,255,1) 37%, rgba(56,87,253,1) 73%, rgba(34,222,241,1) 100%);\n    border: none;\n    text-transform: uppercase;\n    padding: 10px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
