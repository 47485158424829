// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mh-100 {
    min-height: 100vh;
}
#wcm-modal {
    z-index:9999 !important;
}
.wcm-overlay {
    z-index:9999 !important
} 
.wcm-active {
    z-index: 9999 !important;
} `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI;AACJ;AACA;IACI,wBAAwB;AAC5B","sourcesContent":[".mh-100 {\n    min-height: 100vh;\n}\n#wcm-modal {\n    z-index:9999 !important;\n}\n.wcm-overlay {\n    z-index:9999 !important\n} \n.wcm-active {\n    z-index: 9999 !important;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
