// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iconplus {
  padding: 14px 6px 2px 6px;
  border-radius: 10px;
  background-color: #1A0D58;
}

.text-elips {
  max-width: 250px;
}

@media (max-width: 1100px) {
  .text-elips {
    max-width: 100px;
  }
}
@media (max-width: 767px) {
  .text-elips {
    max-width: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/profile/wallethistory/wallethistory.scss"],"names":[],"mappings":"AAAA;EAEI,yBAAA;EACC,mBAAA;EAEA,yBAAA;AADL;;AAMC;EACG,gBAAA;AAHJ;;AAKA;EACI;IACI,gBAAA;EAFN;AACF;AAKC;EACD;IACI,gBAAA;EAHF;AACF","sourcesContent":[".iconplus{\n\n    padding: 14px 6px 2px 6px;\n     border-radius: 10px;\n \n     background-color: #1A0D58;\n \n \n }\n\n .text-elips {\n    max-width: 250px;\n}\n@media (max-width: 1100px){\n    .text-elips {\n        max-width: 100px;\n    }\n    }\n    \n @media (max-width: 767px){\n.text-elips {\n    max-width: 100px;\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
