import config from "../config.json";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setLoading, showConnectModal } from "../redux/slices/commonSlice";
import { useNavigate } from "react-router-dom";
import { get } from "./api/apiHelper";
import { login } from "../redux/slices/userSlice";

// --------- v5 ------- //
import { createThirdwebClient } from "thirdweb";
import { useActiveAccount, useAutoConnect } from "thirdweb/react";
import { createWallet } from "thirdweb/wallets";
import { sepolia } from "thirdweb/chains";


const privateKeyLink = () => {
    return <a target="_blank" rel="noreferrer" style={{ fontSize: "smaller" }} href={`https://embedded-wallet.thirdweb.com/sdk/2022-08-12/embedded-wallet/export?clientId=${config.REACT_PUBLIC_TEMPLATE_CLIENT_ID}`}>Export your wallet</a>
}

const useConnection = () => {
    const walletsConfig = [createWallet("io.metamask"), createWallet("com.coinbase.wallet")];
    const { data } = useAutoConnect({
      client: client,
      accountAbstraction: {
        chain: sepolia, // the chain where your smart accounts will be or is deployed
        sponsorGas: true, // enable or disable sponsored transactions
      },
      wallets: walletsConfig
    });
    console.log(data);
    const activeAccount = useActiveAccount();
    const redirect = useNavigate();
    const dispatch = useDispatch();
    const checkConnection = useCallback((force = true) => {
        if (activeAccount?.address) {
            dispatch(setLoading(true));
            get('/client/profile').then(response => {
                if (response?.status === 200) {
                    let token = localStorage.getItem('accessToken');
                    dispatch(login({ ...response.data, token }));
                }
                // else {
                //     redirect('/battles')
                // }
            }).finally(() => {
                dispatch(setLoading(false));
            });
        }
        // else {
        //     dispatch(setLoading(false));
        //     if (force) {
        //         dispatch(showConnectModal(true))
        //         redirect('/battles')
        //     }
        // }
    }, [dispatch, activeAccount?.address, redirect])

    return { checkConnection }
}
export { privateKeyLink, useConnection }



// --------- v5 ------- //

export const client = createThirdwebClient({
    clientId: config.REACT_PUBLIC_TEMPLATE_CLIENT_ID,
});