import React from "react";
import "../pagenotfound/pagenotfound.css";
import IMG1 from "../../assets/images/notfound.png";
import { useNavigate } from "react-router-dom";
function Pagenotfound() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/')
  }
  return (
    <div className="page py-0">
      <section className="error_page">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-md-5 ">
              <h1 className=" pagenot display-3 d-none d-md-none d-lg-block">
                Page not found :(
              </h1>

              <p className="oops">
                Oops, looks like <br />
                the bots are <br />
                fighting over this <br />
                page, and it looks like  they tore it <br /> apart!
              </p>
              <div className="home_btn">
                <button className=" btn btn-default  " onClick={handleClick}>
                  <span className=" btn-default-text ">HOME</span>
                </button>

              </div>

            </div>

            <div className="col-md-7  text-center">
              <div className="bg_circle">
                <span className="parag ">404</span>

                <div className="main-img">
                  <div className="">
                    <img width={520} src={IMG1} alt="error" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Pagenotfound;
