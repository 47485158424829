// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
  li .pagi {
    padding: 10px 10px 12px 12px;
  }
}
.pagi {
  padding: 14px 19px 14px 19px;
}

.border_gray {
  border: 2px solid gray;
}

.hei-widt li {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 6px;
  width: 48px;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.scss"],"names":[],"mappings":"AAAA;EAEI;IACE,4BAAA;EAAJ;AACF;AAII;EACE,4BAAA;AAFN;;AAMI;EACE,sBAAA;AAHN;;AAMI;EACE,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,WAAA;AAHN","sourcesContent":["@media (max-width:768px){\n\n    li .pagi {\n      padding: 10px 10px 12px 12px;\n  }\n  }\n  \n  \n    .pagi{\n      padding: 14px 19px 14px 19px ;\n    }\n    \n\n    .border_gray{\n      border: 2px solid gray;\n    }\n\n    .hei-widt li{\n      align-items: center;\n      display: flex;\n      justify-content: center;\n      margin: 0 6px;\n      width: 48px;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
