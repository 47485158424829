/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./profileheader.scss";
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from "../../../assets/images/badge.png";
import arrowcyan from "../../../assets/images/arrowcyan.svg";
import Share from "../../../assets/images/Share-1.svg";
import Instagram from "../../../assets/images/instagram.png";
import Facebook from "../../../assets/images/Facebook.png";
import twiter from "../../../assets/images/twiter.png";
import edit from "../../../assets/images/edit.png";
import back from "../../../assets/images/back.png";
import star1 from '../../../assets/images/Star-s1.png';
import star2 from '../../../assets/images/Star-s2.png';
import ethereum from '../../../assets/images/ethereum-eth-logo.svg';
import { BsPlus } from 'react-icons/bs';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { logOut, login } from "../../../redux/slices/userSlice";
import { setWalletConnection } from "../../../redux/slices/commonSlice";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { getImageSrc } from "../../../utils/common";
import { client, privateKeyLink } from "../../../utils/thirdweb";
import { FaXTwitter } from "react-icons/fa6";
import { BiLogoDiscord } from 'react-icons/bi'
import { ConnectButton, useActiveWallet, useDisconnect } from "thirdweb/react";
import { sepolia } from "thirdweb/chains";
import { initializeApp } from "firebase/app";
import { TwitterAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { STORAGE_KEYS, discord_local_url, discord_url, firebaseConfig } from "../../../config";
import { get, post } from "../../../utils/api/apiHelper";
const ProfileHeader = ({ balance, setModalShow }) => {
    const user = useSelector((state) => state.user);
    const [isTwitterLinked, setIsTwitterLinked] = useState(user?.isTwitterVerified || false);
    const [isDiscordLinked, setIsDiscordLinked] = useState(user?.isDiscordVerified || false);
    const wallet = useActiveWallet();
    const { disconnect } = useDisconnect();
    const dispatch = useDispatch();
    const redirect = useNavigate();
    const app = initializeApp(firebaseConfig);
    const provider = new TwitterAuthProvider();
    provider.setCustomParameters({
    'lang': 'en'
    });
    const auth = getAuth(app);
    
    const logOutHandler = () => {
        const options = {
            title: 'Are you sure you want to logout?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        disconnect(wallet);
                        dispatch(logOut());
                        dispatch(setWalletConnection(false));
                        localStorage.setItem('accessToken', "");
                        localStorage.setItem('walletAddress', "");
                        redirect('/battles');
                        toast.success("Logout successfully!");
                    }
                },
                {
                    label: 'No'
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "custom-alert-overlay"
        };
        confirmAlert(options);
    };

    const handleToggle = async (platform) => {
        let loadingToast;
        const newStatus = platform === "twitter" ? !isTwitterLinked : !isDiscordLinked;
        console.log(newStatus,"newwrtyuiehb");
        // Update local state
        if (platform === "twitter") {
            if (newStatus) {
                await authenticateTwitter(); // Follow Twitter
            } else {
                await unfollowTwitter(); // Unfollow Twitter
            }
            setIsTwitterLinked(newStatus);
        } else if(platform==="discord") {
            if (newStatus) {
                loadingToast = toast.loading("Connecting Discord...");
                window.open(discord_url, "_parent")
                // await post('/client/follow_discord',{
                //     discordUserName:"",
                //     access_token_key:"",
                //     access_token_secret:""
                // })
                setIsDiscordLinked(newStatus);
            } else {
                await unfollowDiscord(); // Unfollow Twitter
            }
        }

        // Update in Redux and send API request
        // dispatch(updateUser({ 
        //     isTwitterVerified: platform === "twitter" ? newStatus : isTwitterLinked,
        //     isDiscordVerified: platform === "discord" ? newStatus : isDiscordLinked
        // }));

        // try {
        //     await fetch("/api/update-social-links", {
        //         method: "POST",
        //         headers: { "Content-Type": "application/json" },
        //         body: JSON.stringify({
        //             userId: user.id,
        //             isTwitterVerified: platform === "twitter" ? newStatus : isTwitterLinked,
        //             isDiscordVerified: platform === "discord" ? newStatus : isDiscordLinked
        //         })
        //     });
        // } catch (error) {
        //     console.error("Error updating social links:", error);
        // }
    };

    const authenticateTwitter = async () => {
        let loadingToast;
        try {
          loadingToast = toast.loading("Connecting Twitter ...");
          let result = await signInWithPopup(auth, provider);
          const credential = TwitterAuthProvider.credentialFromResult(result);
          const access_token_key = credential.accessToken;
          const access_token_secret = credential.secret;
          console.log(credential, "twitter creds")
          localStorage.setItem(STORAGE_KEYS.twitter, JSON.stringify({
            access_token_key,
            access_token_secret
          }));
          // The signed-in user info.
          const username = result?.user?.reloadUserInfo?.screenName;
          await post('/client/follow_twitter', {
            twitterUserName: username,
            access_token_secret,
            access_token_key
          });
          window.open('https://twitter.com/intent/follow?screen_name=ChainwarzGaming')
          await get('/client/profile').then(response => {
            if (response?.status === 200) {
              let token = localStorage.getItem('accessToken');
              dispatch(login({ ...response.data, token }));
            }
          });
        } catch (error) {
          console.log(error);
          toast.error("Failed To Connect !", {
            toastId: "error"
          });
        }
        finally {
          toast.done(loadingToast);
        }
      }

    const unfollowTwitter = async () => {
        let loadingToast;
        try {
            loadingToast = toast.loading("Disconnecting Twitter ...");
            const twitterData = JSON.parse(localStorage.getItem(STORAGE_KEYS.twitter));
            if (twitterData) {
                const { access_token_key, access_token_secret } = twitterData;
                await post('/client/unfollow_twitter', {
                    access_token_secret,
                    access_token_key
                });
                localStorage.removeItem(STORAGE_KEYS.twitter);
                await get('/client/profile').then(response => {
                    if (response?.status === 200) {
                        let token = localStorage.getItem('accessToken');
                        dispatch(login({ ...response.data, token }));
                    }
                });
            }    
            toast.success("Twitter disconnected successfully!");
        } catch (error) {
            console.log(error);
            toast.error("Failed To Disconnect !", {
                toastId: "error"
            });
        } finally {
            toast.done(loadingToast);
        }
    };

    const unfollowDiscord = async () => {
        let loadingToast;
        try {
            loadingToast = toast.loading("Disconnecting Discord ...");
            // Make an API call to your backend to revoke the Discord connection
            await post('/client/unfollow_discord', {
                isDiscordLinked: false, // Pass the user ID or any required identifier
            });
    
            // Update local state and Redux store
            setIsDiscordLinked(false);
            localStorage.removeItem(STORAGE_KEYS.discord); // Remove Discord data from local storage
    
            // Fetch updated profile data
            await get('/client/profile').then(response => {
                if (response?.status === 200) {
                    let token = localStorage.getItem('accessToken');
                    dispatch(login({ ...response.data, token }));
                }
            });
    
            toast.success("Discord disconnected successfully!");
        } catch (error) {
            console.error("Error disconnecting Discord:", error);
            toast.error("Failed to disconnect Discord!");
        } finally {
            toast.done(loadingToast);
        }
    };

    return (
        <>
            <div className="profile-header text-center text-md-start d-md-flex">
                <div className="profile-header-image me-3">
                    <figure className="bg-gadient bg-animation mx-auto p-1"><img src={getImageSrc(user?.image)} alt="" className="rounded-2 w-100 h-100 object-fit-cover" /></figure>
                </div>
                <div className="profile-header-content">
                    <div className="d-md-flex">
                        <div className="profile-header-content-name">
                            <h2 className="text-white">{user.username} <img src={Badge} alt="" /></h2>
                            <p className="text-gray"> Joined {user.joinedDate?.split("T")[0]}</p>
                        </div>
                        <div className="social-list ms-auto text-center">
                            <ul>
                                {/* <li>
                                    <Dropdown>
                                        <Dropdown.Toggle className="bg-white p-3 social-list-dropdown" id="dropdown-basic">
                                            <Dropdown.Item href=""><img src={Share} alt="" /></Dropdown.Item>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="social-list-inner">
                                            <Dropdown.Item href="https://www.facebook.com/" target="_blank">{<img src={Facebook} alt="" />}</Dropdown.Item>
                                            <Dropdown.Item href="https://www.twitter.com/" target="_blank">{<img src={twiter} alt="" />}</Dropdown.Item>
                                            <Dropdown.Item href="https://www.instagram.com/" target="_blank">{<img src={Instagram} alt="" />}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li> */}
                                <li><Link to={'/editprofile'} className="social-list-btn bg-white mx-3"><img src={edit} alt="" /></Link></li>
                                <li><span onClick={logOutHandler} className="social-list-btn"><img src={back} alt="" /></span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="profile-header-content-inner">
                        <p className="text-break">{user.walletAddress} <img style={{ cursor: "pointer" }} src={arrowcyan} alt="" onClick={() => {
                            window.open(`https://sepolia.etherscan.io/address/${user.walletAddress}`)
                        }} /></p>

                        <div>
                            <div className="toggle-button-cover d-flex align-items-center">
                                <div className="d-md-flex align-items-center  me-5">

                                    <div className="button-switch" id="button-1">
                                        <input type="checkbox" className="checkbox" defaultChecked={user?.isTwitterVerified && user?.twitterUserName}  onChange={() => handleToggle("twitter")} />
                                        <div className="knobs twitter"></div>
                                        <div className="layer"></div>
                                    </div>

                                    <span className="text-white ms-md-3">Link twitter</span>
                                </div>
                                <div className="d-md-flex align-items-center">

                                    <div className="button-switch " id="button-1">
                                        <input type="checkbox" className="checkbox" defaultChecked={user?.isDiscordVerified && user?.discordUserName} onChange={() => handleToggle("discord")} />
                                        <div className="knobs discord"></div>
                                        <div className="layer "></div>
                                    </div>

                                    <span className="text-white ms-md-3">Link Discord</span>
                                </div>
                            </div>
                            {/* <a rel="noreferrer" href='https://twitter.com/intent/follow?screen_name=ChainwarzGaming' target='_blank'>
                                <FaXTwitter fontSize={32} className='twitter' />
                            </a>
                            <a rel="noreferrer" href='https://discord.gg/3syprfcb8r' target='_blank'>
                                <BiLogoDiscord fontSize={32} className='discord' />
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-2 col-6">
                    <h4 className="text-white">level</h4>
                    <div className="d-md-flex align-items-center   ">
                        <img src={star1} alt="" width={35} />
                        <span className=" h2 text-warning m-0">{user.tier}</span>
                    </div>
                </div>
                <div className="col-md-2 col-6 text-end text-md-start">
                    <h4 className="text-white">points</h4>
                    <div className="d-md-flex align-items-center   ">
                        <img src={star2} alt="" width={35} />
                        <span className=" h2 text-cyan m-0">{user.points}</span>
                    </div>
                </div>
                <div className="col-md-4 col-12 text-start mt-4 mt-md-0">
                    <h4 className="text-white">referral code</h4>
                    <span className="text-break text-cyan h5 p-2">{user.referral_code}</span>
                </div>
                <div className="col-md-4 col-12 text-md-end text-start  mt-4 mt-md-0">
                    <h4 className="text-white">
                        Balance
                    </h4>
                    <div className="">
                        {/* <span className=" h6 text-cyan p-2 text-break">{balance} eth</span> */}
                        <ConnectButton client={client} theme={"light"} accountAbstraction={{
                            chain: sepolia,
                            sponsorGas: true
                        }} />
                        {/* <div className="mt-1">
                            {privateKeyLink()}
                        </div> */}
                        {/* <img alt="" src={ethereum} width={25} />
                        <span className=" h2 text-cyan p-2">{balance?.toFixed(8)} eth</span>
                        <span className="iconplus " onClick={() => setModalShow(true)}>
                            <BsPlus className="text-white h3   " />
                        </span> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileHeader;