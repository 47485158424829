// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.walletList .modal-dialog{
    background-color: #1A0D58;
    width: 448px;
    max-height: 544px;
    border-radius: 15px;
}
.walletList .modal-dialog .modal-body button{
    background-color: #3B57EE;
    border-radius: 32px;
    border: none;
    margin: 5px 0px;
    color: white;
    display: flex;
    justify-content: left;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/layout/header/WalletModal.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,mBAAmB;AACvB","sourcesContent":[".walletList .modal-dialog{\n    background-color: #1A0D58;\n    width: 448px;\n    max-height: 544px;\n    border-radius: 15px;\n}\n.walletList .modal-dialog .modal-body button{\n    background-color: #3B57EE;\n    border-radius: 32px;\n    border: none;\n    margin: 5px 0px;\n    color: white;\n    display: flex;\n    justify-content: left;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
