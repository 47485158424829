// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-loader {
  width: 50px;
  height: 50px;
  display: grid;
}

.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  --c: radial-gradient(farthest-side,#766DF4 92%,#0000);
  background: var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 50%, var(--c) 0 50%;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  animation: s2 1s infinite;
}

.custom-loader::before {
  margin: 4px;
  filter: hue-rotate(45deg);
  background-size: 8px 8px;
  animation-timing-function: linear;
}

@keyframes s2 {
  100% {
    transform: rotate(0.5turn);
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/loader.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;AACJ;;AACA;;EAEI,WAAA;EACA,cAAA;EACA,qDAAA;EACA,gFACE;EAIF,0BAAA;EACA,4BAAA;EACA,yBAAA;AAFJ;;AAIA;EACE,WAAA;EACA,yBAAA;EACA,wBAAA;EACA,iCAAA;AADF;;AAIA;EACE;IAAK,0BAAA;EAAL;AACF","sourcesContent":[".custom-loader {\n    width: 50px;\n    height: 50px;\n    display: grid;\n}\n.custom-loader::before,\n.custom-loader::after {\n    content:\"\";\n    grid-area: 1/1;\n    --c: radial-gradient(farthest-side,#766DF4 92%,#0000);\n    background: \n      var(--c) 50%  0, \n      var(--c) 50%  100%, \n      var(--c) 100% 50%, \n      var(--c) 0    50%;\n    background-size: 12px 12px;\n    background-repeat: no-repeat;\n    animation: s2 1s infinite;\n}\n.custom-loader::before {\n  margin:4px;\n  filter:hue-rotate(45deg);\n  background-size: 8px 8px;\n  animation-timing-function: linear\n}\n\n@keyframes s2{ \n  100%{transform: rotate(.5turn)}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
