// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head-ref {
  color: var(--white, #FFF);
  text-shadow: 0px 0px 64px #5FB2FF;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: -2.88px;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 767px) {
  .head-ref {
    font-size: 36px;
    line-height: 40px;
  }
}

.share {
  color: var(--white, #FFF);
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.96px;
  text-transform: uppercase;
  padding: 0px 100px;
}
@media (max-width: 767px) {
  .share {
    padding: 0px 30px;
  }
}

.one {
  color: #FFD335;
}

.one1 {
  color: #3B83EE;
}

.two {
  color: #FF7F37;
}

.two2 {
  color: var(--blue, #3B57EE);
}

.three {
  color: #FF4E43;
}

.three3 {
  color: var(--lilac, #752CE8);
}

.four {
  color: #FF7F37;
}

.four4 {
  color: #BA63FF;
}

.leaderboader-element p {
  font-size: 12px;
}

.color-2x {
  color: #FF7F37;
}

.color-greater {
  color: #FFD335;
}

.color-less {
  color: #FF4E43;
}`, "",{"version":3,"sources":["webpack://./src/components/Leaderboard/leaderboard.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EAEA,iCAAA;EACG,eAAA;EACH,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;AAAJ;AACI;EAXJ;IAYQ,eAAA;IACA,iBAAA;EAEN;AACF;;AAAA;EACI,yBAAA;EACJ,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;AAGA;AAFA;EAZA;IAaI,iBAAA;EAKF;AACF;;AAFA;EACI,cAAA;AAKJ;;AAHA;EACI,cAAA;AAMJ;;AAJA;EACI,cAAA;AAOJ;;AALA;EACI,2BAAA;AAQJ;;AANA;EACI,cAAA;AASJ;;AANA;EACI,4BAAA;AASJ;;AAPA;EACI,cAAA;AAUJ;;AARA;EACI,cAAA;AAWJ;;AAPI;EACI,eAAA;AAUR;;AAPA;EACI,cAAA;AAUJ;;AAPE;EACE,cAAA;AAUJ;;AAPE;EACE,cAAA;AAUJ","sourcesContent":[".head-ref{\n    color: var(--white, #FFF);\n   \n    text-shadow: 0px 0px 64px #5FB2FF;\n       font-size: 50px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 80px; \n    letter-spacing: -2.88px;\n    text-transform: uppercase;\n    text-align: center;\n    @media(max-width:767px){\n        font-size:36px;\n        line-height: 40px;\n    }\n}\n.share{\n    color: var(--white, #FFF);\ntext-align: center;\njustify-content: center;\nalign-items: center;\nfont-size: 24px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 32px; \nletter-spacing: -0.96px;\ntext-transform: uppercase;\npadding: 0px 100px;\n@media(max-width:767px){\n    padding: 0px 30px;\n}\n}\n\n.one{\n    color: #FFD335;\n}\n.one1{\n    color: #3B83EE;\n}\n.two{\n    color: #FF7F37;\n}\n.two2{\n    color: var(--blue, #3B57EE);\n}\n.three{\n    color: #FF4E43;\n}\n\n.three3{\n    color: var(--lilac, #752CE8);\n}\n.four{\n    color: #FF7F37;\n}\n.four4{\n    color: #BA63FF;\n}\n\n.leaderboader-element{\n    p{\n        font-size: 12px;\n    }\n}\n.color-2x {\n    color: #FF7F37;\n  }\n  \n  .color-greater {\n    color: #FFD335;\n  }\n  \n  .color-less {\n    color: #FF4E43;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
