// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropbtn {
  padding: 12px 34px 12px 22px;
}

.icon-edit {
  border-radius: 6px;
  background-color: #1A0D58;
}

.iconplus {
  padding: 14px 6px 2px 6px;
  border-radius: 10px;
  background-color: #1A0D58;
}

.nav-item.nav-link.active {
  position: relative;
}

.nav-item.nav-link.active:after {
  content: "";
  background-color: #1A0D58;
  width: 100px;
  height: 2px;
  position: absolute;
  bottom: 0;
}

.tab_section .nav-link.active {
  border: none;
  position: relative;
}

.tab_section .nav-link.active:after {
  content: "";
  background: cyan;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -27px;
  left: 0;
}

.buy-btn {
  border: white;
  padding: 10px 18px;
  font-size: 18px;
  margin-bottom: 20px;
  border-radius: 15px;
}

hr.rounded {
  border-top: 6px solid #3B57EE;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/profile/profile.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,yBAAA;AACJ;;AAIA;EAEG,yBAAA;EACC,mBAAA;EAEA,yBAAA;AAHJ;;AAQA;EAA0B,kBAAA;AAJ1B;;AAKE;EACE,WAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;AAFJ;;AAQA;EACI,YAAA;EACA,kBAAA;AALJ;;AAOA;EACI,WAAA;EACA,gBAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,OAAA;AAJJ;;AAOA;EACI,aAAA;EACG,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;AAJP;;AASG;EACI,6BAAA;EACA,kBAAA;AANP","sourcesContent":[".dropbtn{\n    padding:12px 34px 12px 22px;\n}\n\n.icon-edit{\n    border-radius: 6px;\n    background-color:#1A0D58; \n    \n    \n}\n\n.iconplus{\n    \n   padding: 14px 6px 2px 6px;\n    border-radius: 10px;\n\n    background-color: #1A0D58;\n    \n\n}\n\n.nav-item.nav-link.active{position: relative;}\n  .nav-item.nav-link.active:after {\n    content: '';\n    background-color: #1A0D58;\n    width:100px;\n    height:2px;\n    position: absolute;\n    bottom:0;\n}\n\n\n\n\n.tab_section .nav-link.active{\n    border:none;\n    position: relative;\n}\n.tab_section .nav-link.active:after {\n    content: '';\n    background: cyan;\n    width: 100%;\n    height: 2px;\n    position: absolute;\n    bottom: -27px;\n    left: 0;\n}\n\n.buy-btn{\n    border: white;\n       padding: 10px 18px;\n       font-size: 18px;\n       margin-bottom: 20px;\n       border-radius: 15px;\n   \n   }\n   \n   \n   hr.rounded {\n       border-top: 6px solid #3B57EE;\n       border-radius: 4px;\n     }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
