import Modal from "react-bootstrap/Modal";
import "./WalletModal.css";
import METAMASK_ICON from "../../assets/images/Metamask.png";
import COINBASE_ICON from "../../assets/images/Coinbase.png";
import WALLETCONNECT_ICON from "../../assets/images/WalletConnect.png";
import { toast } from "react-toastify";
import { useConnect } from "thirdweb/react";
import { createWallet } from "thirdweb/wallets";
import { sepolia } from "thirdweb/chains";
import { client } from "../../utils/thirdweb";
function WalletModal({ setShow, show }) {
  const wallets = ["Metamask", "Coinbase"];
  const walletsConfig = [createWallet("io.metamask"), createWallet("com.coinbase.wallet")];
  const walletsIcon = [METAMASK_ICON, COINBASE_ICON];
  const { connect } = useConnect({
    client: client,
    accountAbstraction: {
      chain: sepolia, // the chain where your smart accounts will be or is deployed
      sponsorGas: true, // enable or disable sponsored transactions
    },
  });
  const connectToSmartAccount = async (wallet) => {
    try {
      await connect(async () => {
        await wallet.connect({
          client,
          walletConfig: {
            options: "smartWalletOnly"
          }
        });
        return wallet;
      });
    } catch (error) {
      console.error("Error connecting:", error);
      toast.error('Error connecting !', {
        toastId: "error"
      })
    } finally {
      setShow(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} className="walletList d-flex align-Items-center">
        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
        <Modal.Body className="pt-0">
          <>
            <h3 className="text-white text-center">CONNECT</h3>
            <p className="w-50 text-white mx-auto my-3 text-center">
              Choose a wallet connection method
            </p>
            <div className="walletbutton d-grid">
              {wallets.map((wallet, index) => (
                <button
                  key={index}
                  onClick={() => connectToSmartAccount(walletsConfig[index])}
                >
                  <img src={walletsIcon[index]} width={90} alt="" />
                  {wallet}
                </button>
              ))}
              <button
                onClick={() => { }}
              >
                <img src={WALLETCONNECT_ICON} width={90} alt="" />
                Wallet Connect
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WalletModal;
