// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperone {
  background-color: #070123;
  color: azure;
  padding-top: 10rem;
}

.wallet-form {
  background: rgba(59, 87, 238, 0.2);
}

.knobs.twitter.Bell::before {
  content: "\\f0f3";
  font: normal normal normal 14px/1 FontAwesome;
}

.btn-twidis {
  padding: 10px 24px;
  font-size: 20px;
  border-radius: 8px;
  min-width: 200px;
}
.btn-twidis:hover {
  opacity: 0.9;
  background-color: #fff;
}

.btn-twidis-check {
  padding: 10px 24px;
  font-size: 20px;
  border-radius: 8px;
  min-width: 200px;
  background: linear-gradient(29deg, rgb(255, 129, 125) 0%, rgb(130, 51, 255) 37%, rgb(56, 87, 253) 73%, rgb(34, 222, 241) 100%);
}
.btn-twidis-check:hover {
  opacity: 0.9;
  background: linear-gradient(29deg, rgb(255, 129, 125) 0%, rgb(130, 51, 255) 37%, rgb(56, 87, 253) 73%, rgb(34, 222, 241) 100%);
}

input,
input::placeholder {
  font-size: 14px;
}

.wallet-email {
  padding: 1rem;
}

.btn-next {
  width: 100%;
  padding: 14px;
  font-size: 24px;
  border-radius: 8px;
}

.button-switch {
  top: 0%;
}`, "",{"version":3,"sources":["webpack://./src/components/notifications/notifications.scss"],"names":[],"mappings":"AAAA;EAEI,yBAAA;EACA,YAAA;EACA,kBAAA;AAAJ;;AAIA;EAEI,kCAAA;AAFJ;;AAKA;EACI,gBAAA;EACA,6CAAA;AAFJ;;AAKA;EACI,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAFJ;AAII;EACI,YAAA;EACA,sBAAA;AAFR;;AAMA;EACI,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,8HAAA;AAHJ;AAII;EACI,YAAA;EACA,8HAAA;AAFR;;AAOA;;EAEI,eAAA;AAJJ;;AAOA;EACI,aAAA;AAJJ;;AAOA;EACI,WAAA;EACA,aAAA;EACA,eAAA;EAEA,kBAAA;AALJ;;AAQA;EACI,OAAA;AALJ","sourcesContent":[".wrapperone {\n\n    background-color: #070123;\n    color: azure;\n    padding-top: 10rem;\n}\n\n\n.wallet-form {\n\n    background: rgba(59, 87, 238, 0.20);\n\n}\n.knobs.twitter.Bell::before {\n    content: \"\\f0f3\";\n    font: normal normal normal 14px/1 FontAwesome;\n}\n\n.btn-twidis {\n    padding: 10px 24px;\n    font-size: 20px;\n    border-radius: 8px;\n    min-width: 200px;\n\n    &:hover {\n        opacity: 0.9;\n        background-color: #fff;\n    }\n}\n\n.btn-twidis-check {\n    padding: 10px 24px;\n    font-size: 20px;\n    border-radius: 8px;\n    min-width: 200px;\n    background: linear-gradient(29deg, rgba(255, 129, 125, 1) 0%, rgba(130, 51, 255, 1) 37%, rgba(56, 87, 253, 1) 73%, rgba(34, 222, 241, 1) 100%);\n    &:hover {\n        opacity: 0.9;\n        background: linear-gradient(29deg, rgba(255, 129, 125, 1) 0%, rgba(130, 51, 255, 1) 37%, rgba(56, 87, 253, 1) 73%, rgba(34, 222, 241, 1) 100%);\n    }\n}\n\n\ninput,\ninput::placeholder {\n    font-size: 14px;\n}\n\n.wallet-email {\n    padding: 1rem;\n}\n\n.btn-next {\n    width: 100%;\n    padding: 14px;\n    font-size: 24px;\n\n    border-radius: 8px;\n\n}\n.button-switch{\n    top: 0%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
