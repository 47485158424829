import { Fragment, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { scrollIntoView } from "../utils/common";
import Header from "./header/header.components";
import Footer from "./footer/footer.component";
import { useConnection } from '../utils/thirdweb'
import { useInterceptors } from "../utils/api";
import { useActiveAccount } from "thirdweb/react";

export default function AuthLayout() {
    const activeAccount = useActiveAccount();
    const { checkConnection } = useConnection();
    const { pathname, hash } = useLocation();
    const setInterceptors = useInterceptors();

    useEffect(() => {
        setInterceptors();
        checkConnection();
        scrollIntoView(hash);
    }, [pathname, hash, activeAccount?.address, setInterceptors, checkConnection]);
    if (activeAccount?.address) {
        return (
            <Fragment>
                <Header />
                <Outlet />
                <Footer />
            </Fragment>
        );
    }
}