import './modal.scss';
import { React, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import icon from '../../assets/images/ethereum-eth-logo.svg';
import { toast } from 'react-toastify';
import { get, saveTrnx } from '../../utils/api/apiHelper';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/slices/commonSlice';
import { isAddress } from 'ethers/lib/utils';
import { useActiveAccount } from 'thirdweb/react';

const DepositPopup = (props) => {
    const dispatch = useDispatch();
    const activeAccount = useActiveAccount();
    const [counter, setCounter] = useState(0);
    const [adminWallet, setAdminWallet] = useState("");
    const getAdminWallet = async () => {
        let response = await get(`/client/get_settings?key=admin_wallet`)
        if (response.status === 200) {
            let admin_wallet = response?.data?.[0];
            if (admin_wallet) {
                setAdminWallet(admin_wallet?.value)
            }
        }
    }
    useEffect(() => {
        getAdminWallet()
    }, []);
    const deposit = async () => {
        try {
            console.log("deposit", activeAccount?.address);
            if (+counter <= 0) {
                toast.error("Please enter amount greater than zero !");
                return;
            }
            // await switchNetwork({ chainId });
            if (!isAddress(adminWallet)) {
                toast.error("Admin wallet not set for receiving funds !");
                return;
            }
            // dispatch(setLoading(true));
            // const config = await prepareSendTransaction({
            //     chainId: chainId,
            //     account: address,
            //     to: adminWallet,
            //     value: parseEther(`${counter}`)
            // });
            // const { hash } = await sendTransaction(config);
            // const receipt = await waitForTransaction({
            //     chainId: chainId,
            //     hash,
            // });
            // let response = await saveTrnx({
            //     type: "deposit",
            //     amount: counter,
            //     from_address: receipt.from,
            //     to_address: receipt.to,
            //     transaction_hash: receipt.transactionHash,
            //     status: "COMPLETED"
            // });
            // if (response.status === 200) {
            //     toast.success("Deposit Transaction Completed !");
            //     dispatch(setLoading(false));
            //     props.updateData()
            //     props.onHide()
            // } else {
            //     dispatch(setLoading(false));
            //     toast.error(response?.error ?? "Something went wrong.");
            // }
        } catch (error) {
            console.log(error.message, 'error');
            let shortMessage = error?.message?.split('.')?.[0];
            dispatch(setLoading(false));
            toast.error(shortMessage ?? "Transaction Failed !",{
                toastId:"error"
            })
        }
    }
    const withdraw = async () => {
        try {
            console.log("withdraw");
            dispatch(setLoading(true));
            let response = await saveTrnx({
                type: "withdraw",
                amount: counter
            });
            if (response.status === 200) {
                toast.success("Withdraw Request generated !");
                dispatch(setLoading(false));
                props.updateData()
                props.onHide()
            } else {
                dispatch(setLoading(false));
                toast.error(response?.error ?? "Something went wrong.",{
                    toastId:"error"
                });
            }
        } catch (error) {
            console.log(error, "withdraw error");
            toast.error("Something went wrong.",{
                toastId:"error"
            });
        }
    }
    const increase = () => {
        setCounter(count => +count + 1);
    };

    //decrease counter
    const decrease = () => {
        if (counter >= 1) {
            setCounter(count => count - 1);
        }
    };
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Tab.Container id="left-tabs-example" className="" defaultActiveKey="first">

                <Modal.Body className='bg-blue popup-element rounded-4  p-0 pb-4'>
                    <Modal.Header className='border-bottom-0 p-0'>
                        <Col >
                            <Nav variant="pills" className="flex">
                                <Nav.Item className='nav-list' onClick={() => setCounter(0)}>
                                    <Nav.Link eventKey="first" className='nav-listing'>Deposit</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='nav-list' onClick={() => setCounter(0)}>
                                    <Nav.Link eventKey="second" className='nav-listing'>Withdraw</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Modal.Header>
                    <Row>
                        <Col sm={12} >
                            <div className='text-end mb-4'><Button variant="link" onClick={() => { props.onHide(); setCounter(0) }} className='text-decoration-none light-text'>X</Button></div>
                            <Tab.Content className='px-2 px-md-5'>
                                <Tab.Pane className='text-white' eventKey="first">
                                    <div className='d-flex align-items-center'>
                                        <h5 className='mb-0 '>Your balance</h5>
                                        <div className='bg-white rounded-4 p-2 ms-auto '>
                                            <h6 className='text-gradient mb-0 pe-1'><img alt='' src={icon} width={20} className='me-3' />{props?.balance?.toFixed(8)}</h6>
                                        </div>
                                    </div>
                                    {/* <div className='text-center pt-4'>
                                        <h6 className='text-white '>Choose a wallet</h6>

                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" className="bg-transparent text-start dropdown-btn" id="dropdown-basic">
                                                <h5 className='mb-0'> <img alt='' src={wallet1} className='me-1' /> Metamask</h5>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1"> <img alt='' src={wallet1} className='me-1' /> Metamask</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2"> <img alt='' src={wallet1} className='me-1' /> Exodus</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3"> <img alt='' src={wallet1} className='me-1' /> ZenGo</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                    <div className='text-center pt-4'>
                                        <h6 className='text-white '>Choose a wallet</h6>

                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" className="bg-transparent text-start dropdown-btn" id="dropdown-basic">
                                                <h5 className='mb-0'> <img alt='' src={icon} className='me-1' width={30} /> ethereum(ETH)</h5>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1"> <img alt='' src={icon} className='me-1' width={30} /> ethereum(ETH)</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2"> <img alt='' src={icon} className='me-1' width={30} /> ethereum(ETH)</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3"> <img alt='' src={icon} className='me-1' width={30} /> ethereum(ETH)</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div> */}
                                    <div className='text-center pt-4'>
                                        <h6 className='text-white '>How much to deposit?</h6>

                                        <div className="counter">
                                            <div className="btn__container">
                                                <button className="control__btn left " onClick={decrease}>-</button>
                                                <input className="counter__output" value={counter} onChange={(e) => {
                                                    if (Number(e.target.value) >= 0) {
                                                        console.log(e.target.value);
                                                        setCounter(e.target.value)
                                                    }
                                                }} />
                                                <button className="control__btn right" onClick={increase}>+</button>
                                            </div>
                                        </div>
                                        <div className="text-center">  <button className='btn btn-default  py-3 mt-4' onClick={deposit}><span className='text-gradient'>Deposit</span></button></div>

                                    </div>
                                </Tab.Pane>
                                <Tab.Pane className='text-white' eventKey="second">
                                    <div className='d-flex align-items-center'>
                                        <h5 className='mb-0 '>Your balance</h5>
                                        <div className='bg-white rounded-4 p-2 ms-auto '>
                                            <h6 className='text-gradient mb-0 pe-1'><img alt='' src={icon} width={20} className='me-3' />{props?.balance?.toFixed(8)}</h6>
                                        </div>
                                    </div>
                                    {/* <div className='text-center pt-4'>
                                        <h6 className='text-white '>Choose a wallet</h6>

                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" className="bg-transparent text-start dropdown-btn" id="dropdown-basic">
                                                <h5 className='mb-0'> <img alt='' src={wallet1} className='me-1' /> Metamask</h5>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1"> <img alt='' src={wallet1} className='me-1' /> Metamask</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2"> <img alt='' src={wallet1} className='me-1' /> Exodus</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3"> <img alt='' src={wallet1} className='me-1' /> ZenGo</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                    <div className='text-center pt-4'>
                                        <h6 className='text-white '>Choose a wallet</h6>

                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" className="bg-transparent text-start dropdown-btn" id="dropdown-basic">
                                                <h5 className='mb-0'> <img alt='' src={icon} className='me-1' width={30} /> ethereum(ETH)</h5>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1"> <img alt='' src={icon} className='me-1' width={30} /> ethereum(ETH)</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2"> <img alt='' src={icon} className='me-1' width={30} /> ethereum(ETH)</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3"> <img alt='' src={icon} className='me-1' width={30} /> ethereum(ETH)</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div> */}
                                    <div className='text-center pt-4'>
                                        <h6 className='text-white '>How much to withdraw?</h6>

                                        <div className="counter">
                                            <div className="btn__container">
                                                <button className="control__btn left " onClick={decrease}>-</button>
                                                <input className="counter__output" value={counter} onChange={(e) => {
                                                    if (Number(e.target.value) >= 0) {
                                                        console.log(e.target.value);
                                                        setCounter(e.target.value)
                                                    }
                                                }} />
                                                <button className="control__btn right" onClick={increase}>+</button>
                                            </div>
                                        </div>
                                        <div className="text-center">  <button className='btn btn-default  py-3 mt-4' onClick={withdraw}><span className='text-gradient'>Withdraw</span></button></div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Modal.Body>
            </Tab.Container>
        </Modal>
    );
}



export default DepositPopup;