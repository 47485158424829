import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { showConnectModal } from '../../redux/slices/commonSlice';
import ethlogo from "../../assets/images/ethlogo.svg";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import { get } from "../../utils/api/apiHelper";
import { Placeholder } from "react-bootstrap";
import { getImageSrc } from "../../utils/common";
import { useActiveAccount } from "thirdweb/react";

const UpcomingBattle = () => {
  const renderer = ({ days, hours, minutes, seconds }) => {
    return <span>{(days * 24) + (+hours)}:{minutes}:{seconds}</span>;
  };
  const activeAccount = useActiveAccount();
  const { isLogin } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [battles, setBattles] = useState([]);
  const [isFetching, setFetching] = useState(true);
  const getAllBattles = useCallback(async () => {
    try {
      setFetching(true)
      let response = await get(`/client/getBattles`);
      if (response.status === 200) {
        setBattles(response.data.items);
      } else {
        toast.error(response?.error, {
          toastId: "error"
        });
      }
      setFetching(false)
    } catch (error) {
      setFetching(false)
      toast.error('Something went wrong!', {
        toastId: "error"
      });
    }
  }, []);
  useEffect(() => {
    getAllBattles()
    let intervalId = setInterval(getAllBattles, 60000);
    return () => {
      clearInterval(intervalId);
      console.log('cleared');
    }
  }, [getAllBattles])

  if (isFetching) {
    return (
      <div className="row ">
        {
          Array(4).fill(null).map((_, i) => {
            return (
              <div key={i} className="col-md-12 col-lg-6 mt-3">
                <div className="border overflow-hidden position-relative  rounded-4 text-center">
                  <figure className="bg-dark-1 battle-figure"></figure>
                  <div className="battle-content-area pb-3">
                    <Placeholder animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder animation="glow">
                      <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                      <Placeholder xs={6} /> <Placeholder xs={8} />
                    </Placeholder>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    )
  }
  if (!battles.length) {
    return (<div className="row ">
      <p className="text-warning text-center fs-5">
        No Upcoming Battle !
      </p>
    </div>)
  }
  return (
    <div className="row ">
      {
        battles?.map((battle) => {
          return (
            <div key={battle?._id} className="col-md-6 col-lg-6 mt-3 ">
              <div className="border overflow-hidden position-relative  rounded-4 text-center">
                <figure className="img-color4 battle-figure">
                  <span className="position-absolute">#{battle?.battle_no}</span>
                  <img src={getImageSrc(battle?.image)} alt="" className=" border-bottom rounded-4  " />
                </figure>
                <div className="battle-content-area">
                  <div className="  mt-2  btn-h1 ">
                    <div className="d-flex justify-content-center">
                      <span className="t-price text-primary">entry <br />price</span>
                      <div className="d-flex align-items-center gap-1">
                        <img src={ethlogo} width={20} alt="" className="ms-3" />
                        <span className="fs-4 ">{battle?.ticket_price}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-white rounded-2 btn-primary mt-3 btn-pri text-start  btn-gadient">
                    <div className="d-flex justify-content-center ">
                      <span className="t-size">price <br />pool</span>
                      <div className="d-flex align-items-center gap-1">
                        <img src={ethlogo} width={20} alt="" className="ms-3" />
                        <span className="fs-3 ">{battle?.prize_pool?.toFixed(4)}</span>
                      </div>
                    </div>
                  </div>
                  {
                    battle?.isActive ?
                      <>
                        {
                          activeAccount?.address && isLogin
                            ?
                            <Link to={`/battle/${battle?._id}`} className="btn btn-default btn-def mt-4">
                              <span className="btn-default-text fs-5">enter now</span>
                            </Link>
                            :
                            <button onClick={() => dispatch(showConnectModal(true))} className="btn btn-default btn-def mt-4">
                              <span className="btn-default-text fs-5">enter now</span>
                            </button>
                        }
                      </>
                      :
                      <button disabled className="btn btn-default btn-def mt-4">
                        <span className="btn-default-text fs-5">start soon</span>
                      </button>
                  }
                  <div className="countdown-outer  countdown-out countdown  mt-2 bg-gadient battle-counter mx-auto rounded-4">
                    <div className="countdown-inner  bg-dark-1 rounded-3 position-relative">
                      {
                        battle?.isActive ?
                          <Countdown date={battle.end_date} renderer={renderer} />
                          :
                          <Countdown date={battle.start_date} renderer={renderer} />
                      }
                    </div>
                  </div>
                  {
                    battle?.isActive ?
                      (new Date(battle?.end_date).getTime() - Date.now()) < (24 * 3600000) ? <p className="text-primary close mt-3  " >closes in </p> : <p className="py-3"></p>
                      :
                      (new Date(battle?.start_date).getTime() - Date.now()) < (24 * 3600000) ? <p className="text-primary close mt-3  " >starts in </p> : <p className="py-3"></p>
                  }
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  )
}

export default UpcomingBattle;