import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './battlelaunch.scss';
import { Modal } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { FaXTwitter } from "react-icons/fa6";
import ethlogo from "../../assets/images/ethereum-eth-logo.svg";
import Countdown from "react-countdown";
import share from "../../assets/images/shareicon.svg";
import Accordion from "react-bootstrap/Accordion";
import Prizestar1 from "../../assets/images/starprize-1.png";
import Prizestar2 from "../../assets/images/starprize-2.png";
import Prizestar3 from "../../assets/images/starprize-3.png";
import { Link } from 'react-router-dom';
import { get, post } from '../../utils/api/apiHelper';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setAlertData, setLoading, setShowAlert } from '../../redux/slices/commonSlice';
import { getImageSrc } from '../../utils/common';
import LiveChatComponent from '../live-chat/live-chat.component';
import { ethers } from 'ethers';
import { useActiveAccount } from "thirdweb/react";
import { client } from "../../utils/thirdweb";
import { useWalletBalance } from "thirdweb/react";
import { getContract, prepareContractCall, sendAndConfirmTransaction } from "thirdweb";
import { sepolia } from "thirdweb/chains"

let text = `🚀 Ready for an EPIC Battle? 🤩 Entry to the ultimate battle by spreading the word! 🌐 🎮🎁 Tag your gaming squad and dive into the excitement together! 🕹️ Don't miss out on the chance to be part of something extraordinary. 🌟 #GameOn #GamingCommunity #SpreadTheWord 🚀`
LoadingOverlay.propTypes = undefined;
function Spinner() {
  return (
    <div className="custom-loader"></div>
  );
}

const BattleLaunch = () => {
  const [isFetching, setFetching] = useState(true);
  const renderer = ({ days, hours, minutes, seconds }) => {
    return <span>{(days * 24) + (+hours)}:{minutes}:{seconds}</span>;
  };
  const battle_id = window?.location?.pathname?.split('/')?.[2];
  const redirect = useNavigate();
  const activeAccount = useActiveAccount();
  const { data: balance, isLoading, refetch: refetchBalance, isRefetching } = useWalletBalance({
    chain: sepolia,
    address: activeAccount?.address,
    client: client
  });
  const [battle, setBattle] = useState(null);
  const [best, setBest] = useState({});
  const [bundles, setBundles] = useState([]);
  const dispatch = useDispatch();
  const contract = getContract({
    address: battle?.contractAddress,
    chain: sepolia,
    client,
  });
  const getBattleDetails = useCallback(async () => {
    try {
      // dispatch(setLoading(true));
      setFetching(true)
      let response = await get(`/client/getBattle/${battle_id}`);
      if (response.status === 200) {
        let _bundles = response?.data?.bundles;
        setBattle(response.data);
        console.log(_bundles, "_bundles");
        let best_bundle = _bundles?.reduce((a, b) => {
          let a_p = Number(a?.entries / a?.price);
          let b_p = Number(b?.entries / b?.price);
          return a_p > b_p ? a : b
        }, {});
        console.log(best_bundle, 'best_bundle');
        let bundles = _bundles?.filter(b => b?.entries !== best_bundle?.entries)?.sort((a, b) => {
          return (+a?.total_tickets_sold) - (+b?.total_tickets_sold)
        });
        console.log(bundles, 'bundles');
        setBest(best_bundle);
        setBundles(bundles);
        // dispatch(setLoading(false));
        setFetching(false)
      } else {
        // dispatch(setLoading(false));
        setFetching(false)
        toast.error(response?.error);
        redirect('/battles')
      }
    } catch (error) {
      console.log("battle initalization error", error);
      dispatch(setLoading(false));
      toast.error('Something went wrong!', {
        toastId: "error"
      });
    }
  }, [activeAccount?.address]); // eslint-disable-line react-hooks/exhaustive-deps

  const buyBattleTickets = useCallback(async (data) => {
    try {
      dispatch(setShowAlert(false));
      dispatch(setLoading(true));
      const value = ethers.utils.parseEther(data?.price?.toString());
      const transaction = prepareContractCall({
        contract,
        method: "function enterBattle(uint256 numberOfTickets)",
        params: [data.entries],
        value
      });
      const receipt = await sendAndConfirmTransaction({
        account: activeAccount,
        transaction: transaction
      });
      if (receipt.status === 'success') {
        toast.success("Participated in battle successfully !", { toastId: "success" });
        await post(`/client/battleParticipation`, {
          "amout_to_debit": data?.price?.toString(),
          "battle_id": battle_id,
          "from_address": receipt.from,
          "to_address": receipt.to,
          "transaction_hash": receipt.transactionHash,
        }).catch((err) => { console.log(err); });
        dispatch(setLoading(false));
        getBattleDetails()
        refetchBalance()
      } else {
        dispatch(setLoading(false));
        toast.error("Transaction Failed !", {
          toastId: "error"
        });
      }
    } catch (error) {
      console.log(error, "error");
      dispatch(setLoading(false));
      toast.error('Something went wrong!', {
        toastId: "error"
      });
    }
  }, [battle_id, refetchBalance, activeAccount, contract, getBattleDetails, dispatch]);

  useEffect(() => {
    getBattleDetails();
  }, [getBattleDetails]);


  const buyHandler = useCallback(async (data) => {
    dispatch(setLoading(true));
    if (isRefetching || isLoading) {
      toast.info("Please try after some time fetching balance !", {
        toastId: "error"
      });
      dispatch(setLoading(false));
      return;
    }
    if (+balance.displayValue < +data?.price) {
      toast.error("Insufficient funds to participate!", {
        toastId: "error"
      });
      dispatch(setLoading(false));
      return;
    }

    dispatch(setLoading(true));
    get(`/client/getBattle/${battle_id}`).then((response) => {
      if (response?.status === 200) {
        dispatch(setLoading(false));
        const options = {
          title: `Buy ${data?.entries} tickets of ⟠ ${data?.price}?`,
          buttons: [
            {
              label: 'Yes',
              onClick: async () => { await buyBattleTickets(data) }
            },
            {
              label: 'No',
              onClick: () => {
                dispatch(setShowAlert(false));
              }
            }
          ]
        };
        dispatch(setAlertData(options));
        dispatch(setShowAlert(true));
      } else {
        dispatch(setLoading(false));
        toast.error(response?.error, {
          toastId: "error"
        });
      }
    }).catch((error) => {
      dispatch(setLoading(false));
      console.log(error, "check particate error");
      toast.error('Something went wrong!', {
        toastId: "error"
      });
    });
  }, [battle_id, isRefetching, isLoading, balance, buyBattleTickets, dispatch]);

  if (isFetching) {
    return <Modal centered className="loader" show={true}>
      <LoadingOverlay dingOverlay active={true} spinner={<Spinner />} />
    </Modal>

  }
  return (
    <div className="bg-dark-1 pt-head">
      <div className="container">
        <div className="heading-element ">
          <h2 className="text-white text-shadow text-center text-stroke ">
            Epic Battle launch
          </h2>
        </div>
        <div className="row pt-5">
          <div className="col-md-5 mb-3">
            <div className="countdown-outer countdownout ct-out countdown  bg-gadient mx-auto rounded-4">
              <div className="countdown-inner bg-dark-1 ct-in rounded-3 position-relative">
                {battle?.end_date && <Countdown date={battle?.end_date} renderer={renderer} />}
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className='prizepool_element position-relative star'>

              <img src={Prizestar2} alt='' className='star-2 position-absolute' />
              <div className=" prizepool_launch py-4  bg-gadient rounded-4">
                <div className=" text-center  ">
                  <p className='text-white mb-0'>prize pool</p>
                  <h1 className='text-white'>{battle?.prize_pool?.toFixed(4)}</h1>
                </div>
              </div>
              <img src={Prizestar3} alt='' className='star-3 position-absolute' />
              <img src={Prizestar1} alt='' className='star-1 position-absolute' />
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-5  mb-3">
            <div className="prizepooldiv socialbuttons py-4 mt-2">
              <div className=" text-center">
                <h4 className='text-white'>Share this battle</h4>
                {/* <p className='text-white'>share this battle</p> */}
                <div className="social-btn d-flex gap-1 justify-content-center py-1">
                  <a className='btn border-4 p-2 bg-white twitter-share-button'
                    href={`https://twitter.com/intent/tweet?url=https://chainwarz.com${window.location.pathname}&text=${text}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaXTwitter fontSize={22} className="text-primary" />
                  </a>
                  {/* <a href='https://www.discord.com/' rel="noreferrer" target="_blank" className='btn border-4 p-2 bg-white'>
                    <img src={discord} alt='' />
                  </a> */}
                  <Link className='btn border-4 p-2 bg-white'
                    onClick={() => {
                      navigator.clipboard.writeText(`https://chainwarz.com/battle/${battle?._id}`).then(toast.info('Link copied to clipboard !', {
                        toastId: "copy"
                      }))
                    }}
                  >
                    <img src={share} alt='' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 px-3  mb-3">

            <div className='prizepooldiv'>
              <div className="row   mt-2 py-5">


                <div className="col-6 text-center mt-2 ">
                  <span className="t-prize ">You BOUGHT</span>

                  <h1 className="text-center sizet text-white">{battle?.you_bought}</h1>
                </div>
                <div className="col-6 text-center mt-2">
                  <span className="t-prize">Total fighters</span>
                  <h1 className='tgray'>{battle?.total_tickets_sold}</h1>
                </div>
              </div>

            </div>


          </div>
        </div>
        <div className="row mt-1 flex-row-reverse">
          {/* //onClick={() => setModalShow(true)} */}
          <div className="col-md-7 mt-3 mt-md-0 ">
            <div className=" prizepooldiv upcoming cursor-pointer">
              <h1 className="text-center py-4  text-white">enter battle</h1>
              <div className='row'>
                <div className="col-md-12">
                  <div className=' text-center bg-gadient  px-0 pb-0 rounded-top-4'>
                    <h5 className='py-3 mb-0 text-white'>best value</h5>
                    <div className='box-one price-box'>
                      <div >
                        {/* <h2 className='text-info'><sub><span>200</span></sub>400</h2> */}
                        <h2 className='text-info'>{best?.entries}</h2>
                        <p className='text-white'>entries</p>
                      </div>
                      <div className="bg-white rounde-3 btn-default mt-4" onClick={() => buyHandler(best)}>
                        <span className="btn-default-text fs-2">
                          <img src={ethlogo} alt="" />
                          {best?.price}</span>
                      </div>
                      {/* <p className='mb-0 text-white'>Includes 200 free entries</p>
                      <p className='text-primary'>Gas Saving = 400x</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-4'>
                {bundles.map((bun, i) => {
                  return (
                    <div className="col-md-6 box-two pb-4 cursor-pointer" key={bun?._id}>
                      <div className={`overflow-hidden price  rounded-5 text-center  p-1 ${i === 0 && "bg-gadient"}`}>
                        <div className='box-one rounded-5 price-box'>
                          <div>
                            {i === 0 && <div className='price-box-popular text-white'><h5>most popular</h5> </div>}
                            <h2 className='text-info'>{bun?.entries}</h2>
                            <p className='text-white'>entries</p>
                          </div>
                          <div className="bg-white rounde-3 btn-default mt-4" onClick={() => buyHandler(bun)}>
                            <span className="btn-default-text fs-2">
                              <img src={ethlogo} alt="" />
                              {bun?.price}</span>
                          </div>
                          {/* <p className='text-white'>Includes 14 free entries</p>
                          <p className='text-primary'>Gas Saving = 400x</p> */}
                        </div>
                      </div>
                    </div>
                  )
                })
                }
                <div className="col-md-6 box-two pb-4 cursor-pointer">
                  <div className=" overflow-hidden price  rounded-5 text-center  p-1  ">
                    <div className='box-one rounded-5 price-box'>
                      <div>
                        <h2 className='text-info'>1</h2>
                        <p className='text-white'>entry</p>
                      </div>

                      <div className="bg-white rounde-3 btn-default mt-4" onClick={() => buyHandler({ price: battle?.ticket_price, entries: 1 })}>
                        <span className="btn-default-text fs-2">
                          <img src={ethlogo} alt="" />
                          {battle?.ticket_price}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">

            <div className="mt-4">
              <Accordion defaultActiveKey="0" flush className="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className='bordertop'>
                    <h3>Battle participants <span className='text-gray'>({battle?.participants?.length})</span> </h3>{" "}
                  </Accordion.Header>
                  <Accordion.Body className='p-0'>
                    <div className='overflow-auto scroll-white' style={{ maxHeight: "300px" }}>
                      {
                        battle?.participants?.map((participant) => {
                          return (
                            <div className="border-battle py-2" key={participant?._id}>
                              <img className='pic-box' src={getImageSrc(participant?.profile)} alt="" />
                              <span className='ps-3'>{participant?.userName}</span>
                            </div>
                          )
                        })
                      }
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {battle && <LiveChatComponent canSend={battle?.canChat} />}
            </div>
          </div>
        </div>
      </div>
      {/* <BattleEnteredPopup show={modalShow} onHide={() => setModalShow(false)} /> */}
    </div >
  )
}

export default BattleLaunch