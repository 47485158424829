// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formheading {
  line-height: 35px;
}

.formcontrolpadding {
  padding: 1.375rem 0.75rem;
}

.textarea-load {
  padding: 2px;
}
.textarea-load-inner {
  border-radius: 15px;
}

.dark-textarea:focus {
  background-color: #070123 !important;
  color: #fff;
}

.recaptcha-element {
  max-width: 305px;
}
.recaptcha-element iframe {
  scale: 1.2;
}
@media (max-width: 767px) {
  .recaptcha-element iframe {
    scale: 0.9;
  }
}

.char-size {
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/allowlist/allowliststeptwo.scss"],"names":[],"mappings":"AAAA;EAEI,iBAAA;AAAJ;;AAIA;EACI,yBAAA;AADJ;;AAMA;EACI,YAAA;AAHJ;AAKI;EACG,mBAAA;AAHP;;AAQQ;EACI,oCAAA;EACA,WAAA;AALZ;;AASG;EACC,gBAAA;AANJ;AAOI;EACI,UAAA;AALR;AAMQ;EAFJ;IAGQ,UAAA;EAHV;AACF;;AAMG;EACC,eAAA;AAHJ","sourcesContent":[".formheading{\n    \n    line-height: 35px;\n    \n}\n\n.formcontrolpadding{\n    padding: 1.375rem 0.75rem;\n}\n\n\n\n.textarea-load{\n    padding: 2px;   \n    \n    &-inner{\n       border-radius: 15px;\n    }\n   }\n   \n   .dark-textarea{\n        &:focus{\n            background-color: #070123 !important;\n            color:#fff;\n        }\n   }\n\n   .recaptcha-element{\n    max-width:305px;\n    iframe{\n        scale: 1.2;\n        @media(max-width:767px){\n            scale:0.9;\n        }\n    }\n   }\n   .char-size{\n    font-size: 10px;\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
