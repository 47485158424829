// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-icons {
  text-align: center;
}
.social-icons a {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 25px;
  border-radius: 10px;
  margin: 0px 10px;
}
@media (max-width: 767px) {
  .social-icons a {
    padding: 12px;
  }
}

.terms-condition {
  text-align: center;
  padding-bottom: 10px;
}
.terms-condition a {
  color: #fff;
}
@media (max-width: 767px) {
  .terms-condition a {
    display: block;
  }
}

@media (max-width: 767px) {
  .footer-content {
    padding: 0px 20px;
    font-size: 16px;
  }
}
.social-icons .twitter {
  color: #fff;
  font-size: 24px;
}

.social-icons .discord {
  color: #fff;
  font-size: 32px;
}

.social-icons .twitter:hover {
  color: rgb(130, 51, 255);
}

.social-icons .discord:hover {
  color: rgb(130, 51, 255);
}`, "",{"version":3,"sources":["webpack://./src/layout/footer/footer.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;AAAJ;AACI;EACA,6BAAA;EACA,0CAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAAI;EANA;IAOI,aAAA;EAGN;AACF;;AAAA;EACI,kBAAA;EACA,oBAAA;AAGJ;AAFI;EACI,WAAA;AAIR;AAHQ;EAFJ;IAGQ,cAAA;EAMV;AACF;;AAHA;EACA;IACI,iBAAA;IACA,eAAA;EAMF;AACF;AAHA;EACI,WAAA;EACA,eAAA;AAKJ;;AAHA;EACI,WAAA;EACA,eAAA;AAMJ;;AAJA;EACI,wBAAA;AAOJ;;AALA;EACI,wBAAA;AAQJ","sourcesContent":["@import '../../assets/scss/responsive';\n.social-icons{\n    text-align: center;\n    a{\n    background-color: transparent;\n    border: 1px solid rgba(255,255,255,0.4);\n    padding: 25px;\n    border-radius: 10px;\n    margin: 0px 10px;\n    @media(max-width:$breakpoint-mobile){\n        padding: 12px;\n    }\n    }   \n}\n.terms-condition{\n    text-align: center;\n    padding-bottom: 10px;\n    a{\n        color:#fff;\n        @media(max-width:$breakpoint-mobile){\n            display: block;\n        }\n    }\n}\n@media(max-width:$breakpoint-mobile){\n.footer-content{\n    padding: 0px 20px;\n    font-size: 16px;\n}\n}\n\n.social-icons .twitter {\n    color: #fff;\n    font-size: 24px;\n}\n.social-icons .discord {\n    color: #fff;\n    font-size: 32px;\n}\n.social-icons .twitter:hover {\n    color: rgb(130, 51, 255);\n}\n.social-icons .discord:hover  {\n    color: rgb(130, 51, 255);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
