import { Chat } from "@pubnub/chat"
import config from '../config.json'

export default class PubNubChat {
    async init(userId, channelId) {
        console.log({
            publishKey: config.REACT_APP_PUBNUB_PUB_KEY,
            subscribeKey: config.REACT_APP_PUBNUB_SUB_KEY,
            userId,
        }, "keys");
        this.pubnubChat = await Chat.init({
            publishKey: config.REACT_APP_PUBNUB_PUB_KEY,
            subscribeKey: config.REACT_APP_PUBNUB_SUB_KEY,
            userId,
        })
        this.channelId = channelId;
        await this.getChannelDetails();
    }

    async getChannelDetails(users = []) {
        if (!this.channel) {
            const channelDetails = await this.pubnubChat.getChannel(this.channelId);
            if (!channelDetails) {
                const { channel } = await this.createGroupConversation(users);
                this.channel = channel;
            } else {
                this.channel = channelDetails;
            }
        }
    }

    async sendMessage(text, user) {
        await this.getChannelDetails([user]);
        const response = await this.channel.sendText(text,
            {
                storeInHistory: true,
                meta: {
                    user: {
                        image: user?.image,
                        userId: user?.userId,
                        username: user?.username
                    },
                },
            });
        return response;
    }

    async getMessageHistory(users, startTimetoken) {
        await this.getChannelDetails(users);
        const { messages } = await this.channel.getHistory({
            startTimetoken: startTimetoken ? (Number(startTimetoken) - 1).toString() : undefined, // Fetch messages after the last timetoken
        });

        const messagesWithTimestamps = messages.map((message) => ({
            ...message,
            timestamp: new Date(Number(message.timetoken) / 10000).toLocaleString(),
            timetoken: message.timetoken, // Include timetoken for deduplication
        }));

        return messagesWithTimestamps;
    }

    async getMessage(users, timetoken) {
        await this.getChannelDetails(users);
        const message = await this.channel.getMessage(timetoken);
        return message;
    }

    async joinChannel(users) {
        await this.getChannelDetails(users);
        await this.channel.join();
    }


    async createGroupConversation(users) {
        const { channel } = await this.pubnubChat.createGroupConversation({
            users,
            channelId: this.channelId,
        });
        return channel;
    }
}